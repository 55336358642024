<template>
    <ion-page>
        <ion-content>
            <ion-header class="ion-no-border">
                <ion-toolbar>
                    <ion-title>Informes</ion-title>
                    <ion-buttons slot="end">
                        <ion-button @click="exit">
                            <ion-icon :icon="closeOutline" slot="icon-only"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>

            <div style="padding: 1rem">
                <Dropdown v-model.lazy="dia" class="input" :options="deliveryDates" optionLabel="name" optionValue="code" placeholder="Día de Entrega*" />

                <ion-button shape="round" style="margin-top: 2rem" color="dark" expand="full" @click="contarPlatosMenu">
                    <ion-label>Platos -Menú de Navidad</ion-label>
                </ion-button>
                <ion-button shape="round" style="margin-top: 1rem" color="dark" expand="full" @click="contarPlatosCarta">
                    <ion-label>Platos - Carta de Navidad</ion-label>
                </ion-button>
                <ion-button shape="round" style="margin-top: 1rem" color="dark" expand="full" @click="generarTodosPedidos">
                    <ion-label>Todos los Pedidos</ion-label>
                </ion-button>
            </div>
        </ion-content>
    </ion-page>
</template>


<script>
import { IonIcon, IonButton, IonButtons, IonHeader, IonToolbar, modalController, IonLabel, IonTitle, IonPage, IonContent } from '@ionic/vue';
import { trashOutline, createOutline, closeOutline, closeCircleOutline, printOutline, arrowForwardCircleOutline, arrowBackOutline, chevronBackOutline } from 'ionicons/icons';

import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'GenerarInformes',
  props: ['data'],
  components: {
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    Dropdown,
    IonLabel,
    IonTitle,
    IonPage,
    IonContent
  },
    data() {
      return {
            deliveryDates: [
                {name: 'Nochevieja (31 dic)', code: 1640908800000},
                {name: 'Día de Reyes (6 dic)', code: 1670284800000},
            ],

          dia: 1640304000000
      };
    },
    computed: {
        ...mapState('navidad121', {
            elements: state => state.orders,
        }),

        ...mapState(['evolbeUser'])
    },
    methods: {
        contarPlatosMenu(){
            const platos = [{'name': 'nombre', 'quantity': 'cantidad'}];
            let totalMenus = 0;

            this.elements.forEach(element => {
                if(element.deliveryDate === this.dia){
                    const menus = element.items.filter(x => x.id === 'menu-navidad');

                    totalMenus = totalMenus + menus.length;

                    menus.forEach(menu => {
                        menu.items.forEach(option => {
                            const found = platos.find(x => x.name === option.name);
                            if(found){
                                found.quantity = found.quantity + 1;
                            } else{
                                platos.push({
                                    name: option.name,
                                    quantity: 1
                                });
                            }
                        });
                    });
                }
            });

            let csv = ''

            // Loop the array of objects
            for(let row = 0; row < platos.length; row++){
                const keysAmount = Object.keys(platos[row]).length
                let keysCounter = 0

                // If this is the first row, generate the headings
                if(row === 0){
                    // Loop each property of the object
                    for(const key in platos[row]){

                                        // This is to not add a comma at the last cell
                                        // The '\n' adds a new line
                        csv += key + (keysCounter+1 < keysAmount ? ';' : '\r\n' )
                        keysCounter++
                    }
                }else{
                    for(const key in platos[row]){
                        csv += platos[row][key] + (keysCounter+1 < keysAmount ? ';' : '\r\n' )
                        keysCounter++
                    }
                }

                keysCounter = 0
            }

            // Once we are done looping, download the .csv by creating a link
            const link = document.createElement('a')
            link.id = 'download-csv'
            link.setAttribute('href', 'data:application/csv;charset=ISO-8859-1,' + encodeURIComponent(csv));
            link.setAttribute('download', 'platos_menu.csv');
            document.body.appendChild(link)
            document.querySelector('#download-csv').click()
        },

        contarPlatosCarta(){
            const platos = [{'name': 'nombre', 'quantity': 'cantidad'}];
            let totalPlatos = 0;

            this.elements.forEach(element => {
                if(element.deliveryDate === this.dia){
                    const cartas = element.items.filter(x => x.id === 'carta-navidad');

                    totalPlatos = totalPlatos + cartas.length;

                    cartas.forEach(option => {
                        const found = platos.find(x => x.name === option.name);
                        if(found){
                            found.quantity = found.quantity + option.quantity;
                        } else{
                            platos.push({
                                name: option.name,
                                quantity: option.quantity
                            });
                        }
                    })
                }
            });

            let csv = ''

            // Loop the array of objects
            for(let row = 0; row < platos.length; row++){
                const keysAmount = Object.keys(platos[row]).length
                let keysCounter = 0

                // If this is the first row, generate the headings
                if(row === 0){
                    // Loop each property of the object
                    for(const key in platos[row]){

                                        // This is to not add a comma at the last cell
                                        // The '\n' adds a new line
                        csv += key + (keysCounter+1 < keysAmount ? ';' : '\r\n' )
                        keysCounter++
                    }
                }else{
                    for(const key in platos[row]){
                        csv += platos[row][key] + (keysCounter+1 < keysAmount ? ';' : '\r\n' )
                        keysCounter++
                    }
                }

                keysCounter = 0
            }

            // Once we are done looping, download the .csv by creating a link
            const link2 = document.createElement('a')
            link2.id = 'download-csv2'
            link2.setAttribute('href', 'data:csv/plain;charset=ISO-8859-1,' + encodeURIComponent(csv));
            link2.setAttribute('download', 'platos_carta.csv');
            document.body.appendChild(link2)
            document.querySelector('#download-csv2').click()
        },

        generarTodosPedidos(){
            const pedidos = [{'date': 'date', 'name': 'name', 'phone': 'phone', 'direction': 'direction', 'total': 'total', 'payment': 'payment'}];
            let totalPedidos = 0;

            this.elements.forEach(element => {
                if(element.deliveryDate === this.dia){
                    let entrega = 'Recogida en el Restaurante';

                    if(element.customer.address){
                        entrega = element.customer.address + ', '+ element.customer.city + ', ' + element.customer.zipCode;
                    }

                    pedidos.push({
                        date: this.fecha(element.dateCreated),
                        name: element.customer.firstName + ', ' + element.customer.lastName,
                        phone: element.customer.phone,
                        direction: entrega,
                        total: element.total,
                        payment: element.payment.method + ' - ' + element.payment.status,
                    });
                    totalPedidos = totalPedidos + 1;   
                }
            });


            let csv = ''

            // Loop the array of objects
            for(let row = 0; row < pedidos.length; row++){
                const keysAmount = Object.keys(pedidos[row]).length
                let keysCounter = 0

                // If this is the first row, generate the headings
                if(row === 0){
                    // Loop each property of the object
                    for(const key in pedidos[row]){

                                        // This is to not add a comma at the last cell
                                        // The '\n' adds a new line
                        csv += key + (keysCounter+1 < keysAmount ? ';' : '\r\n' )
                        keysCounter++
                    }
                }else{
                    for(const key in pedidos[row]){
                        csv += pedidos[row][key] + (keysCounter+1 < keysAmount ? ';' : '\r\n' )
                        keysCounter++
                    }
                }

                keysCounter = 0
            }

            // Once we are done looping, download the .csv by creating a link
            const link2 = document.createElement('a')
            link2.id = 'download-csv2'
            link2.setAttribute('href', 'data:csv/plain;charset=ISO-8859-1,' + encodeURIComponent(csv));
            link2.setAttribute('download', 'pedidos_navidad.csv');
            document.body.appendChild(link2)
            document.querySelector('#download-csv2').click()
        },

        exit(){
            modalController.dismiss();
        },

        fecha(date){
            return moment(date).format('DD/MM/YYYY - HH:mm');
        },
         
    },
    created(){
      moment.locale('es');
    },
    setup() {
        return { trashOutline, createOutline, closeOutline, closeCircleOutline, printOutline, arrowForwardCircleOutline, arrowBackOutline, chevronBackOutline };
    }
});
</script>

<style scoped>

    .header-aside-container{
        max-width: 96rem;
        margin: 0 auto;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .datos{
      padding: 0;
    }

    .datos ion-item{
      --padding-start: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
    }

    .datos p{
        margin-top: 0.25rem;
    }


    .totals, .subplatos, .platos{
      padding: 0;
    }

    .plato{
      border-bottom: 1px solid var(--ion-color-light-shade);
      padding-bottom: 1rem;
    }

    .dish, .subdish{
        --padding-start: 0px;
        --inner-padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --padding-top: 0px;
        --inner-padding-top: 0px;
        --padding-bottom: 0px;
        --inner-padding-bottom: 0px;
    }

    .totals ion-item, .subplatos ion-item{
        --padding-start: 0px;
        --inner-padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --padding-top: 0px;
        --inner-padding-top: 0px;
        --padding-bottom: 0px;
        --inner-padding-bottom: 0px;
    }

    .dish ion-lablel, .subdish ion-lablel, .totals ion-item ion-label{
        margin: 0;
    }

    .dish .variation, .subdish .variation{
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: var(--ion-color-medium);
        min-height: 0.8rem;
    }

    .price{
        margin: 0;
        width: 88px;
        text-align: right;
    }

    .quantitys{
        width: 68px;
        margin-left: 0;
    }

    .quantitys ion-label{
        margin: 0 auto;
    }

    .subplatos{
        padding: 0;
    }

    #ticket{
      position: absolute;
      z-index: -100;
      right: 10000px;
      top: 0;
    }


</style>
